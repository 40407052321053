<template>
    <div>
        <h5>Seasons</h5>

        <!-- Form Modal -->
        <b-modal
            id="modal-season-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            size="md"
            @ok="submit"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="season.id === null">Create Season</h5>
                <h5 v-if="season.id !== null">Edit Season</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button
                    size="md"
                    variant="primary"
                    @click="ok()"
                    :disabled="saving"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button
                    size="md"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="md-6">
                                <b-form-group labe-for="startYear" label="Start Year">
                                    <validation-provider #default="{ errors }" name="Start Year" rules="">
                                        <b-form-input
                                            id="startYear" v-model="season.startYear" name="satrtYear"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startYear">{{ serverErrors.startYear[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-6">
                                <b-form-group labe-for="endYear" label="End Year">
                                    <validation-provider #default="{ errors }" name="End Year" rules="">
                                        <b-form-input
                                            id="endYear" v-model="season.endYear" name="endYear"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endYear">{{ serverErrors.endYear[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="md-6">
                                <b-form-group labe-for="startDate" label="Start Date">
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="season.startDate" name="satrtDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-6">
                                <b-form-group labe-for="endDate" label="End Date">
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="season.endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group labe-for="status" label="Status">
                                    <validation-provider #default="{ errors }" name="Status" rules="">
                                        <b-form-select
                                            size="md"
                                            v-model="season.status"
                                            id="status"
                                            name="status"
                                            :state="errors.length > 0 ? false : null"
                                            @change="changeStatus()"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option :value="1">Opened</b-form-select-option>
                                            <b-form-select-option :value="0">Closed</b-form-select-option>
                                            <b-form-select-option :value="2">Running</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.status">{{ serverErrors.status[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <!-- <b-card>
                            {{ season }}
                        </b-card> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="md"
                            />
                            <b-button
                                variant="primary"
                                size="md"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap">Create Season</span>
                            </b-button>
                        </div>

                    </b-col>
                    <!-- ./Search -->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
                v-if="!saving"
            >
                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveStatusVariant(data.item.status)}`"
                        class="text-capitalize"
                    >
                        <span v-if="!data.item.status">Closed</span>
                        <span v-if="data.item.status && data.item.isRunning">Running</span>
                        <span v-if="data.item.status && !data.item.isRunning">Opened</span>
                    </b-badge>
                </template>

                <template #cell(startDate)="data">
                    <span class="text-nowrap">
                      {{ data.item.startDate | moment('DD/MM/YYYY') }}
                    </span>
                </template>

                <template #cell(endDate)="data">
                    <span class="text-nowrap">
                      {{ data.item.endDate | moment('DD/MM/YYYY') }}
                    </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>
        
                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="confirmDelete(data.item)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormDatepicker, BFormCheckbox, BModal, VBModal, BButton, BTable,
    BBadge, BDropdown, BDropdownItem, BPagination, BSpinner
 } from 'bootstrap-vue'
import { required, integer } from '@validations'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import seasonsStoreModule from '@/views/cromis/season/seasonsStoreModule'
import useSeasonsList from '@/views/cromis/season/useSeasonsList'
import moment from 'moment'

export default {
    props: {},
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormDatepicker,
        BFormCheckbox,
        BFormSelect,
        BFormSelectOption,
        BModal,
        BButton,
        BSpinner,
        BTable,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
    },
    setup (props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)

        const season = ref({
            id: null,
            startYear: null,
            endYear: null,
            startDate: null,
            endDate: null,
            status: null,
            isRunning: null,
        })

        const CROMIS_SEASON_STORE_MODULE_NAME = 'cromis-season'

        // Register module
        if (!store.hasModule(CROMIS_SEASON_STORE_MODULE_NAME)) store.registerModule(CROMIS_SEASON_STORE_MODULE_NAME, seasonsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(CROMIS_SEASON_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_SEASON_STORE_MODULE_NAME)
        })

        const isAddNewUserSidebarActive = ref(false)

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveStatusVariant,
        } = useSeasonsList()

        // Methods
        const resetForm = () => {
            season.value.id = null
            season.value.startYear = null
            season.value.endYear = null
            season.value.startDate = null
            season.value.endDate = null
            season.value.status = null
            season.value.isRunning = null
        }

        const invokeUpdateForm = (item) => {
            season.value = {
                id: item.id,
                startYear: item.startYear,
                endYear: item.endYear,
                startDate: item.startDate,
                endDate: item.endDate,
                status: item.isRunning? 2 : item.status? 1 : 0,
                isRunning: item.isRunning,
            }

            myModal.value.show()
        }

        const changeStatus = () => {
            if(season.value.status === 0){
                // season.value.status = false
                season.value.isRunning = false
            }
            else{
                season.value.isRunning = false
                if(season.value.status === 2){
                    season.value.isRunning = true
                }
                // season.value.status = true
            }
        }

        const invokeCreateForm = () => {
            season.value.id = null
            season.value.startYear = null
            season.value.endYear = null
            season.value.startDate = null
            season.value.endDate = null
            season.value.status = null
            season.value.isRunning = null

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false 
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if(season.value.id === null || season.value.id === 0)
                handleCreate()
            else
                handleUpdate(season.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            
            await store.dispatch('cromis-season/create', season.value)
                .then(response => {
                    refetch
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Season ${response.data.startYear}/${response.data.endYear} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 2000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if(error.response.status === 422){
                        serverErrors.value = error.response.data.errors
                    }
                    else{
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            await store.dispatch('cromis-season/update', { id: item.id, data: item })
                .then(response => {
                    saving.value = false
                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to season ${response.data.startYear}/${response.data.endYear} has been saved successfully!`,
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })

                    refetch()
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if(error.response.status === 422){
                        serverErrors.value = error.response.data.errors
                    }
                    else{
                        console.log(error)
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        // confirm texrt
        const confirmDelete = (item) => {
            context.root.$swal({
                title: 'Are you sure?',
                text: `The season ${item.startYear}/${item.endYear} will be deleted.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-outline-danger',
                    cancelButton: 'btn btn-outline-primary ml-1',
                },
                buttonsStyling: false,
            }).then(async result => {
                if (result.value) {
                    let success = await remove(item.id)

                    if(success){
                        context.root.$swal({
                            icon: 'info',
                            title: 'Deleted!',
                            text: 'The season has been deleted.',
                            customClass: {
                            confirmButton: 'btn btn-outline-info',
                            },
                        })
                    }
                }
            })
        }

        const remove = async (id) => {
            let success = false

            await store.dispatch('cromis-season/remove', id)
                        .then(response => {
                            success = true
                            refetch()
                        })
                        .catch(error => {
                            success = false
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: false,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        })

            return success
        }

        return {
            // Sidebar
            isAddNewUserSidebarActive,

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filter
            // avatarText,

            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveStatusVariant,

            // Form Data
            season,

            // Validations
            required,
            integer,

            // Methods and Form refs
            myModal,
            saving,
            serverErrors,
            dataForm,
            resetForm,
            invokeCreateForm,
            invokeUpdateForm,
            isFormValid,
            submit,
            handleCreate,
            handleUpdate,
            confirmDelete,
            remove,
            changeStatus,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>